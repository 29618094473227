import Clientes from "../components/Clientes";
import Galeria from "../components/Galeria";

function Portifolio() {
    return (
        <div>
            <Clientes></Clientes>
            <Galeria></Galeria>
        </div>
    )
}

export default Portifolio;

