import React from "react";
import './Sobre.scss'
import missao from "../images/missao.png";
import visao from "../images/visao.png";
import valores from "../images/valores.png";
import image1 from "../images/sobre1.jpg";
import image2 from "../images/sobre2.jpg";

function Sobre() {
    return (
        <div id="sobre" style={{ background: `#fff` }}>
            <div className="wrap">
                <div className="block">
                    <img src={image1} alt="Cachorro representando um personagem humano da OA Comunicação" />
                </div>
                <div className="block-text">
                    <h2>Sobre Nós</h2>
                    <p>A Mascote Top é uma agência de design especializada na criação de mascotes em 3D e diversos estilos. Fazemos parte do Grupo OA Comunicação Digital. Nossa equipe de designers e artistas experientes, combinam criatividade e expertise técnica para dar vida a mascotes e personagens únicos, capazes de estabelecer conexões emocionais profundas e influenciar ações concretas.</p>
                    <p>Acreditamos no poder transformador de um mascote bem-concebido, impulsionando o reconhecimento da marca, a geração de leads e o aumento das vendas. Com processo criativo exclusivo, trabalhamos em estreita colaboração com você ou sua empresa, para criarmos mascotes memoráveis que se alinham perfeitamente aos seus objetivos de comunicação e marketing.</p>
                    <p>Oferecemos diversas opções, desde personagens realistas, animais, objetos animados e infinitos estilos, com personalização para refletir sua identidade corporativa. Utilizamos tecnologia de última geração para garantir que cada detalhe seja meticulosamente trabalhado, resultando em mascotes 3D que são realistas, atraentes e de qualidade excepcional. Se deseja um mascote impactante que deixe sua marca em evidência, entre em contato conosco hoje mesmo para iniciarmos uma parceria que trará singularidade, memorabilidade e eficácia.</p>
                </div>
                <div className="block">
                    <img src={image2} alt="Cachorro representando um personagem humano da OA Comunicação" />
                </div>
            </div>
            <br />
            <div className="vision">
                <div className="block">
                    <img src={missao} alt="Missão" />
                    <h3>Missão</h3>
                    <p>Na Mascote Top, nossa missão é criar mascotes excepcionais que transformam marcas em histórias vivas, utilizando criatividade e paixão para impulsionar o seu sucesso.</p>
                </div>
                <div className="block">
                    <img src={visao} alt="Visão" />
                    <h3>Visão</h3>
                    <p>Nosso objetivo é se destacar no mercado de criação de mascotes, atendendo a negócios que desejam se comunicar por meio de personagens de forma excepcional.</p>
                </div>
                <div className="block">
                    <img src={valores} alt="Valores " />
                    <h3>Valores</h3>
                    <p>Criatividade</p>
                    <p>Qualidade</p>
                    <p>Compromisso</p>
                    <p>Comunicação</p>
                    <p>Satisfação do Cliente</p>
                    <p>Paixão</p>
                </div>
            </div>
        </div>
    )
}


export default Sobre;