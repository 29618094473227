import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/js/src/carousel.js"
import "bootstrap/js/src/util/index.js"
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import HomePage from './pages/Home';
import Portifolio from './pages/Portfolio';
import RootLayout from './pages/Root';
import ErrorPage from './pages/Error';


const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage/>,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/portfolio', element: <Portifolio /> },
    ],
  },

]);




function App() {
  return (
    <div className="App">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
