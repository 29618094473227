import React from "react";
import './Header.scss'
import mascote from '../images/logo-header.png'
import { Link, NavLink } from 'react-router-dom'


function Header() {
    function togleMenu() {
        document.getElementById('menu').classList.contains('show')
            ? document.getElementById('menu').classList.remove('show')
            : document.getElementById('menu').classList.add('show');
    }

    return (
        <div>
            <header>
                <div className="mobile">

                    <Link className="logo" to="/" title="link para home">
                        <img src={mascote} alt='Logo Mascote Top' />
                    </Link>

                    <div className="bars" onClick={togleMenu}>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                </div>
                <nav className="navbar" data-bs-theme="dark" id="menu">

                    <div className="close-menu" onClick={togleMenu}></div>
                    <div className="bg-mobile" onClick={togleMenu}></div>
                    <ul className="nav nav-underline">
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => isActive ? 'active' : 'unactive'}
                                to="/portfolio"
                                onClick={togleMenu} >
                                Portfólio
                            </NavLink>
                        </li>
                       {/*  <li className="nav-item">
                            <a className="nav-link" href="#sobre" onClick={togleMenu}>Sobre</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#servicos" onClick={togleMenu}>Serviços</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href="#footer" onClick={togleMenu}>Contato</a>
                        </li>
                    </ul>
                </nav>
            </header>
        </div>
    );
}

export default Header;
