import React from "react";
import './Servicos.scss'
import image from "../images/servicos.png";
import bgImage from "../images/bg-servicos.jpg";

function Servicos() {
    return (
        <div id="servicos" style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="bg">
                <h2>Serviços</h2>
                <div className="wrap">
                    <div>
                        <img src={image} alt="Macote robótico do time mascote top representando a sessão de serviços" />
                    </div>
                    <div className="text">
                        <p>&bull; <b>Design de mascotes personalizados:</b> Criamos mascotes exclusivos e personalizados para nossos clientes, levando em consideração suas necessidades e preferências específicas.</p>
                        <br />
                        <p>&bull; <b>Ilustração e Modelagem 3D:</b> Utilizando técnicas avançadas de desenho e modelagem 3D, criamos mascotes tridimensionais realistas e envolventes.</p>
                        <br />
                        <p>&bull; <b>Ilustração 2D:</b> Nossa equipe de designers e artistas talentosos, desenvolvem ilustrações 2D detalhadas e cativantes para dar vida aos mascotes.</p>
                        <br />
                        {/* <p>&bull; <b>Animação:</b> Além do design estático, podemos animar os mascotes, adicionando movimento e expressão, o que torna a comunicação da marca mais dinâmica e cativante.</p>
                        <br /> */}
                        <p>&bull; <b>Criação de Logos:</b> Um logo bem projetado transmite confiança e profissionalismo. É a sua oportunidade de causar uma impressão sólida e positiva nos seus clientes em potencial. Trabalhando em harmonia com seu mascote.</p>
                        <br />
                        <p>&bull; <b>Identidade visual:</b> Podemos criar uma identidade visual consistente, incorporando o mascote em diversos materiais de marketing, como logotipos, banners, campanhas publicitárias, entre outros.</p>
                        <br />
                        <p>&bull; <b>Rebranding:</b> Se sua empresa está passando por um processo de rebranding, nossa agência pode ajudar a repensar e redesenhar o mascote para refletir a nova direção da marca.</p>
                        <br />
                        <p>&bull; <b>Consultoria de marca:</b> Oferecemos consultoria especializada para ajudar os clientes a escolher o mascote certo que representa sua marca de forma eficaz.</p>
                       {/*  <br />
                        <p>&bull; <b>Serviços de impressão:</b> Além do design, a agência também pode auxiliar na impressão de materiais de marketing, como cartões de visita, flyers, embalagens, entre outros, que apresentam o mascote.</p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Servicos;