import React from "react";
import './Galeria.scss'
import ImagePort from "./ImagePort";
import mascote1 from "../images/portifolio/1/1.jpg";
import mascote2 from "../images/portifolio/1/2.jpg";
import mascote3 from "../images/portifolio/1/3.jpg";
import mascote4 from "../images/portifolio/1/4.jpg";
import mascote5 from "../images/portifolio/1/5.jpg";
import mascote6 from "../images/portifolio/1/6.jpg";
import mascote7 from "../images/portifolio/1/7.jpg";
import mascote8 from "../images/portifolio/1/8.jpg";
import mascote9 from "../images/portifolio/1/9.jpg";
import mascote10 from "../images/portifolio/1/10.jpg";
import mascote11 from "../images/portifolio/1/11.jpg";
import mascote12 from "../images/portifolio/1/12.jpg?q=2";
import mascote13 from "../images/portifolio/1/13.jpg?q=2";
import mascote14 from "../images/portifolio/1/14.jpg?q=2";
import mascote15 from "../images/portifolio/1/15.jpg?q=2";
import mascote16 from "../images/portifolio/1/16.jpg?q=2";
import mascote17 from "../images/portifolio/1/17.jpg?q=2";
import mascote18 from "../images/portifolio/1/18.jpg?q=2";
import mascote19 from "../images/portifolio/1/19.jpg";
import mascote20 from "../images/portifolio/1/20.jpg";

import mascote21 from "../images/portifolio/2/1.jpg";
import mascote22 from "../images/portifolio/2/2.jpg";
import mascote23 from "../images/portifolio/2/3.jpg";
import mascote24 from "../images/portifolio/2/4.jpg";
import mascote25 from "../images/portifolio/2/5.jpg";
import mascote26 from "../images/portifolio/2/6.jpg";
import mascote27 from "../images/portifolio/2/7.jpg";
import mascote28 from "../images/portifolio/2/8.jpg";
import mascote29 from "../images/portifolio/2/9.jpg";
import mascote30 from "../images/portifolio/2/10.jpg";
import mascote31 from "../images/portifolio/2/11.jpg";
import mascote32 from "../images/portifolio/2/12.jpg";
import mascote33 from "../images/portifolio/2/13.jpg";
import mascote34 from "../images/portifolio/2/14.jpg";
import mascote35 from "../images/portifolio/2/15.jpg";
import mascote36 from "../images/portifolio/2/16.jpg";
import mascote37 from "../images/portifolio/2/17.jpg";
import mascote38 from "../images/portifolio/2/18.jpg";
import mascote39 from "../images/portifolio/2/19.jpg";
import mascote40 from "../images/portifolio/2/20.jpg";

/* import mascote41 from "../images/portifolio/3/1.jpg";
import mascote42 from "../images/portifolio/3/2.jpg";
import mascote43 from "../images/portifolio/3/3.jpg";
import mascote44 from "../images/portifolio/3/4.jpg";
import mascote45 from "../images/portifolio/3/5.jpg";
import mascote46 from "../images/portifolio/3/6.jpg";
import mascote47 from "../images/portifolio/3/7.jpg";
import mascote48 from "../images/portifolio/3/8.jpg";
import mascote49 from "../images/portifolio/3/9.jpg";
import mascote50 from "../images/portifolio/3/10.jpg";
import mascote51 from "../images/portifolio/3/11.jpg";
import mascote52 from "../images/portifolio/3/12.jpg";
import mascote53 from "../images/portifolio/3/13.jpg";
import mascote54 from "../images/portifolio/3/14.jpg";
import mascote55 from "../images/portifolio/3/15.jpg";
import mascote56 from "../images/portifolio/3/16.jpg";
import mascote57 from "../images/portifolio/3/17.jpg";
import mascote58 from "../images/portifolio/3/18.jpg";
import mascote59 from "../images/portifolio/3/19.jpg";
import mascote60 from "../images/portifolio/3/20.jpg"; */

/* import mascote61 from "../images/portifolio/4/1.jpg";
import mascote62 from "../images/portifolio/4/2.jpg";
import mascote63 from "../images/portifolio/4/3.jpg";
import mascote64 from "../images/portifolio/4/4.jpg";
import mascote65 from "../images/portifolio/4/5.jpg";
import mascote66 from "../images/portifolio/4/6.jpg";
import mascote67 from "../images/portifolio/4/7.jpg";
import mascote68 from "../images/portifolio/4/8.jpg";
import mascote69 from "../images/portifolio/4/9.jpg";
import mascote70 from "../images/portifolio/4/10.jpg";
import mascote71 from "../images/portifolio/4/11.jpg";
import mascote72 from "../images/portifolio/4/12.jpg";
import mascote73 from "../images/portifolio/4/13.jpg";
import mascote74 from "../images/portifolio/4/14.jpg";
import mascote75 from "../images/portifolio/4/15.jpg";
import mascote76 from "../images/portifolio/4/16.jpg";
import mascote77 from "../images/portifolio/4/17.jpg";
import mascote78 from "../images/portifolio/4/18.jpg";
import mascote79 from "../images/portifolio/4/19.jpg";
import mascote80 from "../images/portifolio/4/20.jpg"; */


import mascote2D1 from "../images/portifolio/2d/1.jpg";
import mascote2D2 from "../images/portifolio/2d/2.jpg";
import mascote2D3 from "../images/portifolio/2d/3.jpg";
import mascote2D4 from "../images/portifolio/2d/4.jpg";
import mascote2D5 from "../images/portifolio/2d/5.jpg";
import mascote2D6 from "../images/portifolio/2d/6.jpg";
import mascote2D7 from "../images/portifolio/2d/7.jpg";
import mascote2D8 from "../images/portifolio/2d/8.jpg";

import bgImage from "../images/bg-portifolio.jpg";
import Carousel from "react-bootstrap/Carousel";

function Galeria() {
    return (
        <div id="galeria" style={{ backgroundImage: `url(${bgImage})` }} >
            <h2>Portfólio 3D</h2>
            <Carousel interval={null}>
                <Carousel.Item>
                    <div className="container">
                        <div className="top">
                            <ImagePort image={mascote1} number={1} ></ImagePort>
                            <ImagePort image={mascote2} number={2}></ImagePort>
                            <ImagePort image={mascote3} number={3}></ImagePort>
                            <ImagePort image={mascote4} number={4}></ImagePort>
                            <ImagePort image={mascote5} number={5}></ImagePort>
                            <ImagePort image={mascote6} number={6}></ImagePort>
                            <ImagePort image={mascote7} number={7}></ImagePort>
                            <ImagePort image={mascote8} number={8}></ImagePort>
                            <ImagePort image={mascote9} number={9}></ImagePort>
                            <ImagePort image={mascote10} number={10}></ImagePort>
                            <ImagePort image={mascote11} number={11}></ImagePort>
                            <ImagePort image={mascote12} number={12}></ImagePort>
                            <ImagePort image={mascote13} number={13}></ImagePort>
                            <ImagePort image={mascote14} number={14}></ImagePort>
                            <ImagePort image={mascote15} number={15}></ImagePort>
                            <ImagePort image={mascote16} number={16}></ImagePort>
                            <ImagePort image={mascote17} number={17}></ImagePort>
                            <ImagePort image={mascote18} number={18}></ImagePort>
                            <ImagePort image={mascote19} number={19}></ImagePort>
                            <ImagePort image={mascote20} number={20}></ImagePort>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="container">
                        <div className="top">
                            <ImagePort image={mascote21} number={21} ></ImagePort>
                            <ImagePort image={mascote22} number={22}></ImagePort>
                            <ImagePort image={mascote23} number={23}></ImagePort>
                            <ImagePort image={mascote24} number={24}></ImagePort>
                            <ImagePort image={mascote25} number={25}></ImagePort>
                            <ImagePort image={mascote26} number={26}></ImagePort>
                            <ImagePort image={mascote27} number={27}></ImagePort>
                            <ImagePort image={mascote28} number={28}></ImagePort>
                            <ImagePort image={mascote29} number={29}></ImagePort>
                            <ImagePort image={mascote30} number={30}></ImagePort>
                            <ImagePort image={mascote31} number={31}></ImagePort>
                            <ImagePort image={mascote32} number={32}></ImagePort>
                            <ImagePort image={mascote33} number={33}></ImagePort>
                            <ImagePort image={mascote34} number={34}></ImagePort>
                            <ImagePort image={mascote35} number={35}></ImagePort>
                            <ImagePort image={mascote36} number={36}></ImagePort>
                            <ImagePort image={mascote37} number={37}></ImagePort>
                            <ImagePort image={mascote38} number={38}></ImagePort>
                            <ImagePort image={mascote39} number={39}></ImagePort>
                            <ImagePort image={mascote40} number={40}></ImagePort>
                        </div>
                    </div>
                </Carousel.Item>
               {/*  <Carousel.Item>
                    <div className="container">
                        <div className="top">
                            <ImagePort image={mascote41} number={41} ></ImagePort>
                            <ImagePort image={mascote42} number={42}></ImagePort>
                            <ImagePort image={mascote43} number={43}></ImagePort>
                            <ImagePort image={mascote44} number={44}></ImagePort>
                            <ImagePort image={mascote45} number={45}></ImagePort>
                            <ImagePort image={mascote46} number={46}></ImagePort>
                            <ImagePort image={mascote47} number={47}></ImagePort>
                            <ImagePort image={mascote48} number={48}></ImagePort>
                            <ImagePort image={mascote49} number={49}></ImagePort>
                            <ImagePort image={mascote50} number={50}></ImagePort>
                            <ImagePort image={mascote51} number={51}></ImagePort>
                            <ImagePort image={mascote52} number={52}></ImagePort>
                            <ImagePort image={mascote53} number={53}></ImagePort>
                            <ImagePort image={mascote54} number={54}></ImagePort>
                            <ImagePort image={mascote55} number={55}></ImagePort>
                            <ImagePort image={mascote56} number={56}></ImagePort>
                            <ImagePort image={mascote57} number={57}></ImagePort>
                            <ImagePort image={mascote58} number={58}></ImagePort>
                            <ImagePort image={mascote59} number={59}></ImagePort>
                            <ImagePort image={mascote60} number={60}></ImagePort>
                        </div>
                    </div>
                </Carousel.Item> */}
                {/* <Carousel.Item>
                    <div className="container">
                        <div className="top">
                            <ImagePort image={mascote61} number={61} ></ImagePort>
                            <ImagePort image={mascote62} number={62}></ImagePort>
                            <ImagePort image={mascote63} number={63}></ImagePort>
                            <ImagePort image={mascote64} number={64}></ImagePort>
                            <ImagePort image={mascote65} number={65}></ImagePort>
                            <ImagePort image={mascote66} number={66}></ImagePort>
                            <ImagePort image={mascote67} number={67}></ImagePort>
                            <ImagePort image={mascote68} number={68}></ImagePort>
                            <ImagePort image={mascote69} number={69}></ImagePort>
                            <ImagePort image={mascote70} number={70}></ImagePort>
                            <ImagePort image={mascote71} number={71}></ImagePort>
                            <ImagePort image={mascote72} number={72}></ImagePort>
                            <ImagePort image={mascote73} number={73}></ImagePort>
                            <ImagePort image={mascote74} number={74}></ImagePort>
                            <ImagePort image={mascote75} number={75}></ImagePort>
                            <ImagePort image={mascote76} number={76}></ImagePort>
                            <ImagePort image={mascote77} number={77}></ImagePort>
                            <ImagePort image={mascote78} number={78}></ImagePort>
                            <ImagePort image={mascote79} number={79}></ImagePort>
                            <ImagePort image={mascote80} number={80}></ImagePort>
                        </div>
                    </div>
                </Carousel.Item> */}
            </Carousel>

            <br/>
            <br/>
            <br/>
            <br/>
            <h2>Portfólio 2D</h2>
            <Carousel interval={null} slide={false} controls={false} indicators={false}>
                <Carousel.Item>
                    <div className="container">
                        <div className="top">
                            <ImagePort image={mascote2D1} number={201} ></ImagePort>
                            <ImagePort image={mascote2D2} number={202}></ImagePort>
                            <ImagePort image={mascote2D3} number={203}></ImagePort>
                            <ImagePort image={mascote2D4} number={204}></ImagePort>
                            <ImagePort image={mascote2D5} number={205}></ImagePort>
                            <ImagePort image={mascote2D6} number={206}></ImagePort>
                            <ImagePort image={mascote2D7} number={207}></ImagePort>
                            <ImagePort image={mascote2D8} number={208}></ImagePort>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default Galeria;