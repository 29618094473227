import CarouselBootstrap from "../components/Carousel";
import Clientes from "../components/Clientes";
import Servicos from "../components/Servicos";
import Sobre from "../components/Sobre";


function HomePage() {
    return (
        <div>
            <CarouselBootstrap/>
            <Clientes></Clientes>
            <Sobre></Sobre>
            <Servicos></Servicos>
        </div>
    )
}

export default HomePage;