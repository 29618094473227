import React from "react";
import Carousel from "react-bootstrap/Carousel";
import ImagePort from "./ImagePort";

import cliente101 from "../images/portifolio/sucesso/1.jpg";
import cliente102 from "../images/portifolio/sucesso/2.jpg";
import cliente103 from "../images/portifolio/sucesso/3.jpg";
import cliente104 from "../images/portifolio/sucesso/4.jpg";
import cliente105 from "../images/portifolio/sucesso/5.jpg";
import cliente106 from "../images/portifolio/sucesso/6.jpg";
import cliente107 from "../images/portifolio/sucesso/7.jpg";
import cliente108 from "../images/portifolio/sucesso/8.jpg";
import cliente109 from "../images/portifolio/sucesso/9.jpg";
import cliente110 from "../images/portifolio/sucesso/10.jpg";
import cliente111 from "../images/portifolio/sucesso/11.jpg";
import cliente112 from "../images/portifolio/sucesso/12.jpg";
import cliente113 from "../images/portifolio/sucesso/13.jpg";
import cliente114 from "../images/portifolio/sucesso/14.jpg";
import cliente115 from "../images/portifolio/sucesso/15.jpg";
import cliente116 from "../images/portifolio/sucesso/16.jpg";
import cliente117 from "../images/portifolio/sucesso/17.jpg";
import cliente118 from "../images/portifolio/sucesso/18.jpg";
import cliente119 from "../images/portifolio/sucesso/19.jpg";
import cliente120 from "../images/portifolio/sucesso/20.jpg";
import cliente121 from "../images/portifolio/sucesso/21.jpg";
import cliente122 from "../images/portifolio/sucesso/22.jpg";
import cliente123 from "../images/portifolio/sucesso/23.jpg";
import cliente124 from "../images/portifolio/sucesso/24.jpg";
import cliente125 from "../images/portifolio/sucesso/25.jpg";
import cliente126 from "../images/portifolio/sucesso/26.jpg";
import cliente127 from "../images/portifolio/sucesso/27.jpg";
import cliente128 from "../images/portifolio/sucesso/28.jpg";
import "./Clientes.scss"

function Clientes() {
    return (
        <div id="clientes">
            <h2>Clientes</h2>
            <Carousel controls={false} indicators={false} interval={null}>
                <Carousel.Item>
                    <div className="container">
                        <div className="top">
                            <ImagePort image={cliente101} number={101} ></ImagePort>
                            <ImagePort image={cliente102} number={102}></ImagePort>
                            <ImagePort image={cliente103} number={103}></ImagePort>
                            <ImagePort image={cliente104} number={104}></ImagePort>
                            <ImagePort image={cliente105} number={105} ></ImagePort>
                            <ImagePort image={cliente106} number={106}></ImagePort>
                            <ImagePort image={cliente107} number={107}></ImagePort>
                            <ImagePort image={cliente108} number={108}></ImagePort>
                            <ImagePort image={cliente109} number={109}></ImagePort>
                            <ImagePort image={cliente110} number={110}></ImagePort>
                            <ImagePort image={cliente111} number={111}></ImagePort>
                            <ImagePort image={cliente112} number={112}></ImagePort>
                            <ImagePort image={cliente113} number={113}></ImagePort>
                            <ImagePort image={cliente114} number={114}></ImagePort>
                            <ImagePort image={cliente115} number={115}></ImagePort>
                            <ImagePort image={cliente116} number={116}></ImagePort>
                            <ImagePort image={cliente117} number={117}></ImagePort>
                            <ImagePort image={cliente118} number={118}></ImagePort>
                            <ImagePort image={cliente119} number={119}></ImagePort>
                            <ImagePort image={cliente120} number={120}></ImagePort>
                            <ImagePort image={cliente121} number={121}></ImagePort>
                            <ImagePort image={cliente122} number={122}></ImagePort>
                            <ImagePort image={cliente123} number={123}></ImagePort>
                            <ImagePort image={cliente124} number={124}></ImagePort>
                            <ImagePort image={cliente125} number={125}></ImagePort>
                            <ImagePort image={cliente126} number={126}></ImagePort>
                            <ImagePort image={cliente127} number={127}></ImagePort>
                            <ImagePort image={cliente128} number={128}></ImagePort>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}


export default Clientes;